module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

    var figuresData = content.figures || {};
    var revision    = content.revision;

    if (!figuresData.figures) {
      figuresData.figures = {};
    }

__p+='\n\n        <table id="collectionInfoTable" class="arango-table">\n          ';
 if (figuresData.journalSize) { 
__p+='\n            <tr id="collectionSizeBox">\n              <th class="collectionInfoTh2">Journal size:</th>\n              <th class="collectionInfoTh">\n                <div id="show-collection-size" class="modal-text">\n                  '+
((__t=(prettyBytes(figuresData.journalSize)))==null?'':__t)+
'\n                </div>\n              </th>\n              <th class="tooltipInfoTh">\n                <div>\n                  <span class="modalTooltips arangoicon icon_arangodb_info" title="The maximal size of a journal or datafile (in MB). Must be at least 1.">\n                  </span>\n                </div>\n              </th>\n            </tr>\n          ';
 } 
__p+='\n          <tr id="collectionSyncBox">\n            <th class="collectionInfoTh2">Wait for sync:</th>\n            <th class="collectionInfoTh">\n                <div id="show-collection-sync" class="modal-text">\n                  '+
((__t=(figuresData.waitForSync))==null?'':__t)+
'\n                </div>\n            </th>\n            <th class="tooltipInfoTh">\n              <div>\n                <span class="modalTooltips arangoicon icon_arangodb_info" title="Synchronize to disk before returning from a create or update of a document.">\n                </span>\n              </div>\n            </th>\n          </tr>\n\n          <tr>\n            <th class="collectionInfoTh2">ID:</th>\n            <th class="collectionInfoTh">\n              <div id="show-collection-id" class="modal-text">\n                '+
((__t=(content.model.get("id")))==null?'':__t)+
'\n              </div>\n            <th>\n          </tr>\n\n          <tr id="collectionRevBox">\n            <th class="collectionInfoTh2">Revision:</th>\n            <th class="collectionInfoTh">\n              <div id="show-collection-rev" class="modal-text">\n                '+
((__t=(revision.revision))==null?'':__t)+
'\n              </div>\n            </th>\n            <th class="collectionInfoTh">\n            </th>\n          </tr>\n\n          <tr>\n            <th class="collectionInfoTh2">Type:</th>\n            <th class="collectionInfoTh">\n              <div class="modal-text">'+
((__t=(content.model.get("type")))==null?'':__t)+
'</div>\n            </th>\n            <th class="collectionInfoTh">\n            </th>\n          </tr>\n\n          <tr>\n            <th class="collectionInfoTh2">Status:</th>\n            <th class="collectionInfoTh">\n              <div class="modal-text">'+
((__t=(content.model.get("status")))==null?'':__t)+
'</div>\n            </th>\n            <th class="collectionInfoTh">\n            </th>\n          </tr>\n\n          ';
 if (figuresData.numberOfShards) { 
__p+='\n            <tr>\n              <th class="collectionInfoTh2">Number of shards:</th>\n              <th class="collectionInfoTh">\n                <div class="modal-text">'+
((__t=(figuresData.numberOfShards))==null?'':__t)+
'</div>\n              </th>\n              <th class="collectionInfoTh">\n              </th>\n            </tr>\n          ';
 } 
__p+='\n          \n          ';
 if (figuresData.shards) { 
__p+='\n            <tr>\n              <th class="collectionInfoTh2">Shards:</th>\n              <th class="collectionInfoTh">\n                <div class="modal-text">\n                ';
 var allShards = Object.keys(figuresData.shards); 
                   allShards.forEach(function(shardName, index) {
                
__p+='\n                '+
((__t=((index > 0 ? '<br />' : '')))==null?'':__t)+
'\n                '+
((__t=(shardName))==null?'':__t)+
': [ '+
((__t=(figuresData.shards[shardName].join('  -  ') ))==null?'':__t)+
' ] \n                \n                ';
});
__p+='\n                </div>\n              </th>\n              <th class="collectionInfoTh">\n              </th>\n            </tr>\n          ';
 } 
__p+='\n          \n          ';
 if (figuresData.replicationFactor) { 
__p+='\n            <tr>\n              <th class="collectionInfoTh2">Replication factor:</th>\n              <th class="collectionInfoTh">\n                <div class="modal-text">'+
((__t=(figuresData.replicationFactor))==null?'':__t)+
'</div>\n              </th>\n              <th class="collectionInfoTh">\n              </th>\n            </tr>\n          ';
 } 
__p+='\n          ';
 if (figuresData.writeConcern) { 
__p+='\n            <tr>\n              <th class="collectionInfoTh2">Write concern:</th>\n              <th class="collectionInfoTh">\n                <div class="modal-text">'+
((__t=(figuresData.writeConcern))==null?'':__t)+
'</div>\n              </th>\n              <th class="collectionInfoTh">\n              </th>\n            </tr>\n          ';
 } 
__p+='\n          \n          ';
 if (figuresData.shardKeys) { 
__p+='\n            <tr>\n              <th class="collectionInfoTh2">Shard keys:</th>\n              <th class="collectionInfoTh">\n                <div class="modal-text">'+
((__t=(JSON.stringify(figuresData.shardKeys)))==null?'':__t)+
'</div>\n              </th>\n              <th class="collectionInfoTh">\n              </th>\n            </tr>\n          ';
 } 
__p+='\n\n          ';
 if (figuresData.distributeShardsLike) { 
__p+='\n            <tr>\n              <th class="collectionInfoTh2">Distribute shards like:</th>\n              <th class="collectionInfoTh">\n                <div class="modal-text">'+
((__t=(figuresData.distributeShardsLike))==null?'':__t)+
'</div>\n              </th>\n              <th class="collectionInfoTh">\n              </th>\n            </tr>\n          ';
 } 
__p+='\n\n          ';
 if (figuresData.smartJoinAttribute) { 
__p+='\n            <tr>\n              <th class="collectionInfoTh2">Smart join attribute:</th>\n              <th class="collectionInfoTh">\n                <div class="modal-text">'+
((__t=(figuresData.smartJoinAttribute))==null?'':__t)+
'</div>\n              </th>\n              <th class="collectionInfoTh">\n              </th>\n            </tr>\n          ';
 } 
__p+='\n          \n          ';
 if (figuresData.indexBuckets) { 
__p+='\n            <tr>\n              <th class="collectionInfoTh2">Index buckets:</th>\n              <th class="collectionInfoTh">\n                <div class="modal-text">'+
((__t=(figuresData.indexBuckets))==null?'':__t)+
'</div>\n              </th>\n              <th class="collectionInfoTh">\n              </th>\n            </tr>\n          ';
 } 
__p+=' \n          ';
 if (!frontendConfig.isCluster) { 
__p+='\n            ';
 if (figuresData.figures.compactionStatus) { 
__p+='\n              <tr>\n                <th class="collectionInfoTh2">Compaction status:</th>\n                <th class="collectionInfoTh">\n                  <div class="modal-text">'+
((__t=(figuresData.figures.compactionStatus.message + " (" + figuresData.figures.compactionStatus.time + ")" ))==null?'':__t)+
'</div>\n                </th>\n                <th class="collectionInfoTh">\n                </th>\n              </tr>\n            ';
 } 
__p+=' \n          ';
 } 
__p+='\n          \n          ';
 if (figuresData.figures.waitingFor) { 
__p+='\n            <tr>\n              <th class="collectionInfoTh2">Waiting for:</th>\n              <th class="collectionInfoTh">\n                <div class="modal-text">'+
((__t=(figuresData.figures.waitingFor))==null?'':__t)+
'</div>\n              </th>\n              <th class="collectionInfoTh">\n              </th>\n            </tr>\n          ';
 } 
__p+='\n        </table>\n\n      </div>\n\n        ';
 if (figuresData.figures.alive && figuresData.figures.dead && !frontendConfig.isCluster && frontendConfig.engine === 'mmfiles') { 
__p+='\n            <div class="infoBox">\n              <h4>Figures</h4>\n              <p>The following information does not contain data stored in the write-ahead log. It can be inaccurate, until the write-ahead log has been completely flushed.</p>\n            </div>\n          <table class="figures1 arango-table">\n            <tr class="figuresHeader">\n              <th>Type</th>\n              <th>Count</th>\n              <th>Size</th>\n              <th>Deletion</th>\n              <th>Info</th>\n            </tr>\n            <tr>\n              <th class="modal-text">Alive</th>\n              ';
 if (figuresData.walMessage) { 
__p+=' \n                <th class="modal-text">'+
((__t=(figuresData.walMessage))==null?'':__t)+
'</th>\n              ';
 } else { 
__p+='\n                <th class="modal-text">'+
((__t=(numeral(figuresData.figures.alive.count).format('0,0')))==null?'':__t)+
'</th>\n              ';
 } 
__p+='\n              <th class="modal-text">\n                ';
 if (figuresData.walMessage) { 
__p+='\n                  '+
((__t=(figuresData.walMessage))==null?'':__t)+
'\n                ';
 } else { 
__p+='\n                  '+
((__t=(prettyBytes(figuresData.figures.alive.size)))==null?'':__t)+
'\n                ';
 } 
__p+='\n              </th>\n              <th class="modal-text"> -</th>\n              <th class="tooltipInfoTh">\n                <span class="modalTooltips arangoicon icon_arangodb_info" title="Total number and size of all living documents.">\n                </span>\n              </th>\n            </tr>\n            <tr>\n              <th class="modal-text">Dead</th>\n              <th class="modal-text">\n                ';
 if (figuresData.walMessage) { 
__p+='\n                  '+
((__t=(figuresData.walMessage))==null?'':__t)+
'\n                ';
 } else { 
__p+='\n                  '+
((__t=(numeral(figuresData.figures.dead.count).format('0,0')))==null?'':__t)+
'\n                ';
 } 
__p+='\n              </th>\n              <th class="modal-text">\n                ';
 if (figuresData.walMessage) { 
__p+='\n                  '+
((__t=(figuresData.walMessage))==null?'':__t)+
'\n                ';
 } else { 
__p+='\n                  '+
((__t=(prettyBytes(figuresData.figures.dead.size)))==null?'':__t)+
'\n                ';
 } 
__p+='\n              </th>\n              <th class="modal-text">\n                ';
 if (figuresData.walMessage) { 
__p+='\n                  '+
((__t=(figuresData.walMessage))==null?'':__t)+
'\n                ';
 } else { 
__p+='\n                  '+
((__t=(figuresData.figures.dead.deletion))==null?'':__t)+
'\n                ';
 } 
__p+='\n              </th>\n\n              <th class="tooltipInfoTh">\n                <div>\n                  <span class="modalTooltips arangoicon icon_arangodb_info" title="Total number and size of all dead documents.">\n                  </span>\n              </div>\n              </th>\n\n            </tr>\n            <tr><th><div> </div></th></tr>\n          </table>\n        ';
 } 
__p+='\n\n        ';
 if (figuresData.figures.datafiles && figuresData.figures.journals && figuresData.figures.compactors && !frontendConfig.isCluster && frontendConfig.engine === 'mmfiles') { 
__p+='\n          <table class="figures2 arango-table">\n            <tr class="figuresHeader">\n              <th>Type</th>\n              <th>Count</th>\n              <th>Size</th>\n              <th></th>\n              <th>Info</th>\n            </tr>\n            <tr>\n              <th class="modal-text">Datafiles</th>\n              <th class="modal-text">\n                ';
 if (figuresData.walMessage) { 
__p+='\n                  '+
((__t=(figuresData.walMessage))==null?'':__t)+
'\n                ';
 } else { 
__p+='\n                  '+
((__t=(numeral(figuresData.figures.datafiles.count).format('0,0')))==null?'':__t)+
'\n                ';
 } 
__p+='\n              </th>\n              <th class="modal-text">\n                ';
 if (figuresData.walMessage) { 
__p+='\n                  '+
((__t=(figuresData.walMessage))==null?'':__t)+
'\n                ';
 } else { 
__p+='\n                  '+
((__t=( prettyBytes(figuresData.figures.datafiles.fileSize) ))==null?'':__t)+
'\n                ';
 } 
__p+='\n              </th>\n              <th class="modal-text">&nbsp;</th>\n              <th class="tooltipInfoTh">\n                <div>\n                  <span class="modalTooltips arangoicon icon_arangodb_info" data-toggle="tooltip" data-placement="left" title="Number and total size of active datafiles.">\n                  </span>\n                </div>\n              </th>\n            </tr>\n            <tr>\n              <th class="modal-text">Journals</th>\n              <th class="modal-text">\n                ';
 if (figuresData.walMessage) { 
__p+='\n                  '+
((__t=(figuresData.walMessage))==null?'':__t)+
'\n                ';
 } else { 
__p+='\n                  '+
((__t=(numeral(figuresData.figures.journals.count).format('0,0')))==null?'':__t)+
'\n                ';
 } 
__p+='\n              </th>\n              <th class="modal-text">\n                ';
 if (figuresData.walMessage) { 
__p+='\n                  '+
((__t=(figuresData.walMessage))==null?'':__t)+
'\n                ';
 } else { 
__p+='\n                  '+
((__t=(prettyBytes(figuresData.figures.journals.fileSize)))==null?'':__t)+
'\n                ';
 } 
__p+='\n              </th>\n              <th class="modal-text">&nbsp;</th>\n              <th class="tooltipInfoTh">\n                <span class="modalTooltips arangoicon icon_arangodb_info" title="Number and total size of journal files.">\n                </span>\n              </th>\n            </tr>\n            <tr>\n              <th class="modal-text">Compactors</th>\n              <th class="modal-text">\n                ';
 if (figuresData.walMessage) { 
__p+='\n                  '+
((__t=(figuresData.walMessage))==null?'':__t)+
'\n                ';
 } else { 
__p+='\n                  '+
((__t=(numeral(figuresData.figures.compactors.count).format('0,0')))==null?'':__t)+
'\n                ';
 } 
__p+='\n              </th>\n              <th class="modal-text">\n                ';
 if (figuresData.walMessage) { 
__p+='\n                  '+
((__t=(figuresData.walMessage))==null?'':__t)+
'\n                ';
 } else { 
__p+='\n                  '+
((__t=(prettyBytes(figuresData.figures.compactors.fileSize)))==null?'':__t)+
'\n                ';
 } 
__p+='\n              </th>\n              <th class="modal-text">&nbsp;</th>\n              <th class="tooltipInfoTh">\n                <span class="modalTooltips arangoicon icon_arangodb_info" title="Number and total size of compactor files."></span>\n              </th>\n            </tr>\n            <tr>\n              <th class="modal-text">Indexes</th>\n              <th class="modal-text">\n                '+
((__t=(numeral(figuresData.figures.indexes.count).format('0,0')))==null?'':__t)+
'\n              </th>\n              <th class="modal-text">\n                '+
((__t=(prettyBytes(figuresData.figures.indexes.size)))==null?'':__t)+
'\n              </th>\n              <th class="modal-text">&nbsp;</th>\n              <th class="tooltipInfoTh">\n                <span class="modalTooltips arangoicon icon_arangodb_info" title="Number and total memory usage of indexes."></span>\n              </th>\n            </tr>\n          </table>\n        ';
 } 
__p+='\n\n        ';
 if (figuresData.figures.documentReferences && figuresData.figures.uncollectedLogfileEntries && !frontendConfig.isCluster && frontendConfig.engine === 'mmfiles') { 
__p+='\n          <table class="figures3 arango-table">\n            <tr class="figuresHeader">\n              <th>Type</th>\n              <th>Count</th>\n              <th></th>\n              <th></th>\n              <th>Info</th>\n            </tr>\n            <tr>\n              <th class="modal-text">Uncollected</th>\n              <th class="modal-text">'+
((__t=(figuresData.figures.uncollectedLogfileEntries))==null?'':__t)+
'</th>\n              <th class="modal-text">&nbsp;</th>\n              <th class="modal-text">&nbsp;</th>\n              <th class="tooltipInfoTh">\n                <span class="modalTooltips arangoicon icon_arangodb_info" title="Total number of uncollected WAL entries">\n                </span>\n              </th>\n            </tr>\n            <tr>\n              <th class="modal-text">References</th>\n              <th class="modal-text">'+
((__t=(figuresData.figures.documentReferences))==null?'':__t)+
'</th>\n              <th class="modal-text">&nbsp;</th>\n              <th class="modal-text">&nbsp;</th>\n              <th class="tooltipInfoTh">\n                <span class="modalTooltips arangoicon icon_arangodb_info" title="Total number of objects pointing to documents in collection datafiles">\n                </span>\n              </th>\n            </tr>\n          </table>\n        ';
 } 
__p+='\n\n';
}
return __p;
};
